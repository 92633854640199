/**
 * Grid
 */

$column-padding: 1rem;
$margin-bottom: 1rem;

// The rows have a negative margin  which are offset by the padding on the columns.
%column-padding {
  padding-left: $column-padding;
  padding-right: $column-padding;
}

%row-margin {
  margin-left: -$column-padding;
  margin-right: -$column-padding;
}

// Flex row
.flex-row {
  @extend %row-margin;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-small,
.flex-large {
  @extend %column-padding;
  flex-basis: 100%;
  margin-bottom: $margin-bottom;
}

/* Small screen breakpoint */

@include small-breakpoint {
  .flex-small {
    flex: 1;
    margin-bottom: 0;
    &.half {
      flex: 0 0 calc(1 / 2 * 100%);
    }
    &.one-fourth {
      flex: 0 0 calc(1 / 4 * 100%);
    }
    &.three-fourths {
      flex: 0 0 calc(3 / 4 * 100%);
    }
    &.one-third {
      flex: 0 0 calc(1 / 3 * 100%);
    }
    &.two-thirds {
      flex: 0 0 calc(2 / 3 * 100%);
    }
  }
}

/* Large screen breakpoint */

@include large-breakpoint {
  .flex-large {
    flex: 1;
    margin-bottom: 0;
    &.half {
      flex: 0 0 calc(1 / 2 * 100%);
    }
    &.one-fourth {
      flex: 0 0 calc(1 / 4 * 100%);
    }
    &.three-fourths {
      flex: 0 0 calc(3 / 4 * 100%);
    }
    &.one-third {
      flex: 0 0 calc(1 / 3 * 100%);
    }
    &.two-thirds {
      flex: 0 0 calc(2 / 3 * 100%);
    }
  }
}
